import { Component, OnInit } from '@angular/core';
import * as TextConstants from '../../../../constants/text.constants';
import { BrandingService } from '../../../../services/branding.service';
import { WelcomeFilesService } from '../../../../services/welcome-files.service';

@Component({
  selector: 'app-e-logo',
  templateUrl: './e-logo.component.html',
  styleUrl: './e-logo.component.scss',
})
export class ELogoComponent implements OnInit {
  imgUrl: string = '';
  textConstants = TextConstants;

  constructor(private brandingService: BrandingService, private mediaFileService: WelcomeFilesService) {}

  ngOnInit(): void {
    this.brandingService.getBranding().subscribe((branding) => {
      if (branding.logoFileId !== null) {
        this.getImage(branding.logoFileId);
      } else {
        this.imgUrl = TextConstants.WLD_LOGO_BLACK_IMG_PATH;
      }
    });
  }

  getImage(imgId: string) {
    this.mediaFileService.getByIdAndUpdateUrl(imgId, 'welcomefiles').subscribe((info) => (this.imgUrl = info.url));
  }
}
