import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import * as RouteConstants from '../constants/route.constants';
import { Branding, FooterLink } from '../models/Branding';

@Injectable({
  providedIn: 'root',
})
export class BrandingService {
  private routeConstants = RouteConstants.BRANDING_URLS;

  constructor(private api: ApiService) {}

  getBranding(): Observable<Branding> {
    return this.api.get(this.routeConstants.GET_BRANDING);
  }

  updateFooterFreeText(request: { freeText: string }) {
    return this.api.put(this.routeConstants.UPDATE_FOOTER_FREE_TEXT, request);
  }

  updateOtherTypes(request: { footerLinks: FooterLink[] }) {
    return this.api.put(this.routeConstants.UPDATE_OTHER_TYPES, request);
  }

  updateBrandingLogo(request: FormData) {
    return this.api.put(this.routeConstants.UPDATE_BRANDING_LOGO, request);
  }
}
