import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-e-button',
  templateUrl: './e-button.component.html',
  styleUrl: './e-button.component.scss',
})
export class EButtonComponent {
  @Input() textContent: string = '';
  @Input() btnClass!: string;
  @Input() disabled: boolean = false;
  @Output() btnClick = new EventEmitter<void>();

  onButtonClick() {
    this.btnClick.emit();
  }
}
